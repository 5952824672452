<template>
  <div class="container-fluid pl-0 pr-0">
    <h5>ID: {{ publisher.id }} - {{ publisher.display_name }}</h5>
    <div class="row">
      <div class="col">
        <vs-card>
          <h6>Ad Accounts</h6>
          <vs-button size="small" class="mb-2 btn-sm" color="primary" @click="AddAdAccount()">New Ad Account</vs-button>
          <div class="table-container">
            <table class="table table-responsive-md">
              <thead>
                <tr>
                  <th class="text-center">ID</th>
                  <th>Name</th>
                  <th>Platform</th>
                  <th>External ID</th>
                  <th>Code Name</th>
                  <th>Timezone</th>
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="account in accounts" :key="account.id">
                  <td class="text-center">
                    <b-badge>{{ account.id }}</b-badge>
                  </td>
                  <td>
                    <span>{{ account.name }}</span>
                  </td>
                  <td>
                    <span>{{ account.platform }}</span>
                  </td>
                  <td>
                    <span>{{ account.external_id }}</span>
                  </td>
                  <td>
                    <span>{{ account.code_name }}</span>
                  </td>
                  <td>
                    <span>{{ account.timezone }}</span>
                  </td>
                  <td>
                    <a @click="editAccount(account)">Edit</a>
                  </td>
                </tr>
              </tbody>
            </table>
            <clip-loader color="#20313b" v-if="loading" />
          </div>
        </vs-card>
      </div>
    </div>
  </div>
</template>

<script>
import ClipLoader from "vue-spinner/src/ClipLoader.vue";

export default {
  data: function () {
    return {
      accounts: [],
      publisher: {},
      loading: false,
    };
  },
  mounted() {
    this.load();
  },
  computed: {},
  watch: {},
  methods: {
    AddAdAccount: function () {
      this.$router.push(
        `/admin/publishers/${this.publisher.id}/ad_accounts/new`
      );
    },
    editAccount: function (account) {
      this.$router.push(
        `/admin/publishers/${this.publisher.id}/ad_accounts/${account.id}/edit`
      );
    },
    load: async function () {
      this.loading = true;
      await this.$http
        .get(`${this.host}/publishers/${this.$route.params.id}.json`)
        .then((res) => {
          this.publisher = res.data;
        });
      await this.$http
        .get(`${this.host}/ad_accounts.json?publisher=${this.$route.params.id}`)
        .then((res) => {
          this.accounts = res.data.ad_accounts;
        })
        .finally((_) => {
          this.loading = false;
        });
    },
  },
  components: {
    ClipLoader,
  },
};
</script>
<style scoped>
</style>
