<template>
  <vs-card>
    <div v-if="submitError" class="alert alert-danger">{{ submitError }}</div>
    <table class="text-center table table-bordered table-responsive-md">
      <thead>
      <tr>
        <th class="date-col"                                >Date</th>
        <th class="user-col"                                >User</th>
        <th class="campaign-col" v-if="!params.campaign_id">Campaign</th>
        <th class="event-col"                              >Event</th>
        <th class="changed-col"                            >Changed Fields</th>
        <th class="new-col"                                >New Value</th>
        <th class="prev-col"                               >Previous Value</th>
        <th v-if="siteIdColumnEnabled" class="site-col"    >Site ID</th>
      </tr>
      </thead>
      <tbody>
      <tr>
        <td>
          <date-range-picker v-if="!modalSelectedDate" class="form-control" v-model="range" :options="rangeOptions"></date-range-picker>
        </td>
        <td>
            <select v-model="userFilter">
              <option v-for="user in users" v-bind:value="user.id" v-bind:key="user.id">
                {{ user.first_name }} {{ user.last_name }}
              </option>
            </select>
        </td>
        <td></td>
        <td>
          <select v-model="eventFilter">
            <option v-for="event in events" v-bind:value="event.event" v-bind:key="event.event">
              {{ event.name }}
            </option>
          </select>
        </td>
        <td></td>
        <td></td>
      </tr>
      <tr v-if="isGettingData">
        <td colspan="6">
          <div>
            <b-spinner label="Loading..."></b-spinner>
          </div>
        </td>
      </tr>
      <tr v-for="item in audits" :key="item['id']">
        <td>{{ item['label'] }}</td>
        <td>{{ item['user'] ? item['user']['first_name'] : 'N/A'}} {{ item['user'] ? item['user']['last_name'] : '' }}</td>
        <td v-if="!params.campaign_id">{{ item['campaign']['display_name'] || item['campaign']['name'] }}</td>
        <td v-html="eventName(item)"></td>
        <td>{{ item['changed_field'] }}</td>
        <td>{{ item['field_value'] || item['event_value'] }}</td>
        <td>{{ item['prev_value'] }}</td>
        <td v-if="siteIdColumnEnabled">{{ item['site_id'] }}</td>
      </tr>
      </tbody>
    </table>
  </vs-card>
</template>

<script>
  import moment from 'moment'
  import { datePickerRanges } from "../utils/values.js";

  export default {
    props: ['entityType', 'entity', 'modalSelectedDate'],
    data() {
      return {
        users:          [],
        range:          [],
        audits:         [],
        events:         [],
        userFilter:     null,
        eventFilter:    null,
        submitError:    null,
        isGettingData:  true,
        rangeOptions: {
          alwaysShowCalendars: true,
          maxSpan: { "days": 14 },
          maxDate: moment().add(1,'days'),
          autoApply: true,
          ranges: datePickerRanges(),
        },
      }
    },
    beforeMount(){
      this.getUsers();
      this.range = this.modalSelectedDate ? [this.modalSelectedDate.start, this.modalSelectedDate.end] : [moment(), moment()];
    },
    computed: {
      params: function() {
        let p = {}
        if (this.entity) {
          p[`${this.entityType}_id`] = this.entity.id
          if (this.entityType == 'adset') p['campaign_id'] = this.entity.campaign_id
        }
        return p
      },
      siteIdColumnEnabled: function() {
        return this.audits.some(a => a.site_id)
      },
    },
    methods: {
      eventName(item) {
        let eventName = [ item['event'] || item['event_name'] ];
        if (item['ad']) eventName.push(item['ad']['name']);
        return eventName.join('<br>')
      },
      getData() {
        this.audits         = [];
        this.isGettingData  = true;
        this.$http.post(`${this.host}/campaigns/audits`, this.params)
            .then(res => {
              this.audits = res.data.audits;
              this.events = res.data.events
            })
            .catch(res => {
              this.submitError = res.response.data.error;
            })
            .finally(() => {
              this.isGettingData = false
            })
      },
      getUsers() {
        this.$http.get(`${this.host}/users`, this.params)
            .then(res => {
              this.users = [{ first_name: 'All', id: null }].concat(res.data.users)
            })
            .catch(res => {
              this.submitError = res.response.data.error;
            })
      },
      getDates(value) {
        this.params.start = value.start || moment(value[0], 'DD/MM/YYYY').startOf('day');
        this.params.end   = value.end   || moment(value[1], 'DD/MM/YYYY').endOf('day');
        this.params.timezone = this.params.start.tz();
        this.getData();
      }
    },
    watch: {
      userFilter: function(value) {
        this.params.user_id = value;
        this.getData();
      },
      range: function(value) {
        this.getDates(value);
      },
      modalSelectedDate: {
        deep: true,
        handler(value) {
          this.getDates(value);
        }
      },
      eventFilter: function(value, _o) {
        this.params.event = value;
        this.getData();
      },
    }
  }
</script>
<style scoped>
  * {
    font-size: 12px;
  }
  .table-scroll {
    overflow-x: scroll;
  }
  .filter {
    padding: 10px;
    background-color: aliceblue;
  }
  .date-col {
    width: 115px;
  }
  .user-col {
    width: 65px;
  }
  .event-col {
    width: 128px;
  }
  .prev-col {
    width: 156px;
  }
  .form-control {
    padding: 0 0 0 3px;
  }
  .changed-col {
    width: 110px;
  }
  .new-col {
    width: 120px;
  }
  .campaign-col {
    width: 200px;
  }
  .site-col {
    width: 100px;
  }
  table {
    table-layout: fixed;
    width: 100%;
    word-wrap: break-word;
  }

</style>
