<template>
  <div>
    <h6>{{ user.id }}</h6>
    <form autocomplete="off" @submit.prevent="submit">
      <vs-card>
        <div class="form-group row justify-content-center">
          <label
            for="user_first_name"
            class="text-right col-sm-2 col-form-label"
            >First Name</label
          >
          <div class="col-sm-4">
            <input
              id="user_first_name"
              name="user_first_name"
              class="form-control"
              v-model="user.first_name"
              required
            />
          </div>
        </div>
        <div class="form-group row justify-content-center">
          <label for="user_last_name" class="text-right col-sm-2 col-form-label"
            >Last Name</label
          >
          <div class="col-sm-4">
            <input
              id="user_last_name"
              name="user_last_name"
              class="form-control"
              v-model="user.last_name"
              required
            />
          </div>
        </div>
        <div class="form-group row justify-content-center">
          <label for="email" class="text-right col-sm-2 col-form-label"
            >Email</label
          >
          <div class="col-sm-4">
            <input
              id="email"
              name="email"
              class="form-control"
              v-model="user.email"
              required
            />
          </div>
        </div>

        <div class="form-group row justify-content-center">
          <label for="role" class="text-right col-sm-2 col-form-label"
            >Role</label
          >
          <div class="col-sm-4">
            <select id="role" required v-model="user.role" class="form-control">
              <option
                :key="opt.value"
                :value="opt.value"
                v-for="opt in roles"
              >
                {{ opt.label }}
              </option>
            </select>
          </div>
        </div>
      </vs-card>
      <b-button
        type="submit"
        class="mb-2"
        variant="success"
        :disabled="creating"
      >
        {{ submitButtonText }}
      </b-button>
      <div v-if="submitError" class="alert alert-danger">{{ submitError }}</div>
    </form>
  </div>
</template>

<script>
const DEFAULT_ROLE_FOR_NEW_USER = "peasant";
const USER_ROLES = [
  { value: "admin", label: "admin" },
  { value: "content", label: "content" },
  { value: "peasant", label: "peasant" },
];

export default {
  data: function () {
    return {
      user: {
        role: DEFAULT_ROLE_FOR_NEW_USER,
      },
      roles: USER_ROLES,
      submitError: null,
      creating: false,
      mode: "new",
      submitButtonText: "Create User",
    };
  },
  mounted() {
    const { params } = this.$route;
    if (params.id) {
      this.mode = "edit";

      this.submitButtonText = "Save User";
      this.$http.get(`${this.host}/users/${params.id}.json`).then((res) => {
        this.user = res.data.user;
      });
    }
  },
  beforeDestroy() {},
  computed: {},
  watch: {},
  methods: {
    submit() {
      this.submitError = null;
      this.creating = true;

      let method = this.mode == "edit" ? this.$http.put : this.$http.post;

      method(`${this.host}/users/${this.mode == "edit" ? this.user.id : ""}`, {
        user: this.user,
      })
        .then(() => {
          this.$router.push({
            name: "admin-users",
          });
        })
        .catch((err) => {
          this.submitError = err;
        })
        .finally(() => {
          this.creating = false;
        });
    },
  },
  components: {},
};
</script>
<style scoped></style>
