<template>
  <div class="container-fluid pl-0 pr-0">
    <div class="row">
      <div class="col">
        <vs-button
          size="small"
          class="mb-2 btn-sm"
          color="primary"
          @click="addUser()"
          >New User</vs-button
        >
        <vs-card>
          <div class="table-container">
            <table class="table table-responsive-md">
              <thead>
                <tr>
                  <th class="text-center">ID</th>
                  <th>Name</th>
                  <th>Domain</th>
                  <th>Email</th>
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="user in users" :key="user.id">
                  <td class="text-center">
                    <b-badge>{{ user.id }}</b-badge>
                  </td>
                  <td>
                    <span>{{ user.first_name }} {{ user.last_name }}</span>
                  </td>
                  <td>
                    <span>{{ user.email }}</span>
                  </td>
                  <td>
                    <span>{{ user.role }}</span>
                  </td>
                  <td>
                    <a @click="editUser(user)">Edit</a>
                  </td>
                </tr>
              </tbody>
            </table>
            <clip-loader color="#20313b" v-if="loading" />
          </div>
        </vs-card>
      </div>
    </div>
  </div>
</template>

<script>
import ClipLoader from "vue-spinner/src/ClipLoader.vue";

export default {
  data: function () {
    return {
      users: [],
      loading: false,
    };
  },
  mounted() {
    this.load();
  },
  computed: {},
  watch: {},
  methods: {
    addUser: function () {
      this.$router.push(`/admin/users/new`);
    },
    editUser: function (user) {
      this.$router.push(`/admin/users/${user.id}/edit`);
    },
    load: function () {
      this.loading = true;
      this.$http
        .get(`${this.host}/users.json?full=true`)
        .then((res) => {
          this.users = res.data.users;
        })
        .finally((_) => {
          this.loading = false;
        });
    },
  },
  components: {
    ClipLoader,
  },
};
</script>
<style scoped></style>
