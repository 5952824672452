<template>
  <vs-card>
    <div slot="header">
      <span @click="removeAsset(bindingKey)" class="btn btn-light">
        <i class="fas fa-trash-alt"></i>
      </span>
    </div>

    <div class="form-group row justify-content-center" >
      <label class="text-right col-sm-2 col-form-label">Type</label>
      <div class="col-sm-4 wf-middle">
        <template v-for="item in assetTypes">
          <input
            :key="item"
            :id       = "`rb-${item}-${bindingKey}`"
            type      = "radio"
            class     = "wf-radio"
            :value    = "item"
            v-model   = "assetType"
            :disabled = "!platform"
            required
          >
          <label :for="`rb-${item}-${bindingKey}`" class="wf-radio-label" :key="`${item}-${bindingKey}`">
            {{ item === 'Text' && platform === 'snapchat' ? 'System Title' : item }}
          </label>
        </template>
      </div>
    </div>

    <template v-if="showDropzone">
      <div class="form-group row justify-content-center" >
        <label class="text-right col-sm-2 col-form-label">Files</label>
        <div class="col-sm-4">
          <input type="text" name="dz-required" class="hidden-required" required v-model="anyfilesAdded"/>
          <vue-dropzone
            :awss3="awss3"
            @vdropzone-file-added="fileUploadStarted"
            @vdropzone-success="fileUploadCompleted"
            @vdropzone-removed-file="fileRemoved"
            @vdropzone-canceled="fileUploadCanceled"
            @vdropzone-error="(file) => {}"
            @vdropzone-s3-upload-error="(errorMsg) => {}"
            @vdropzone-s3-upload-success="(location) => {}"
            :id="`dz-${bindingKey}`" ref="myVueDropzone" class="dz"
            :key="`${platform}-${assetType}-${subtype}`"
            :options="dropOptions()">
          </vue-dropzone>
        </div>
      </div>

      <div v-if="Object.keys(videos).length > 0" class="form-group row justify-content-center">
        <label class="text-right col-sm-2 col-form-label">Audio</label>
        <div class="col-sm-4">
          <div v-for="(vid, uuid) in videos" :key="uuid" >
            <i @click="vid.hasAudio = !vid.hasAudio" class="audio-btn m-2" :class="vid.hasAudio ? 'fas fa-volume' : 'fas fa-volume-mute'"></i>
            {{ vid.name }}
          </div>
        </div>
      </div>
    </template>

    <template v-if="assetType === 'Text' && platform">
      <div class="form-group row justify-content-center">
        <label class="text-right col-sm-2 col-form-label">{{ platform === 'snapchat' ? 'System Title' : 'Headline' }} <span v-if="(platformOptions.restrictions || {}).headline_max_chars" :class="headline.length < (platformOptions.restrictions || {}).headline_max_chars ? 'valid' : 'invalid'" >{{ headline.length }}/{{ (platformOptions.restrictions || {}).headline_max_chars }}</span></label>
        <div class="col-sm-4">
          <input required v-model="headline" :maxlength="(platformOptions.restrictions || {}).headline_max_chars" type="text" class="form-control"/>
        </div>
      </div>

      <div v-if="platformOptions.ad_caption" class="form-group row justify-content-center" >
        <label class="text-right col-sm-4 col-form-label">Caption</label>
        <div class="col-sm-6">
          <textarea required v-model="caption" type="text" class="form-control"/>
        </div>
      </div>
    </template>

    <div class="form-group row justify-content-center">
      <label class="text-right col-sm-2 col-form-label typo__label">Tags</label>
      <div class="col-sm-4">
        <multiselect
          v-model="tags"
          class="tags-picker"
          :disabled="!platform"
          tag-placeholder="Add this as new tag"
          placeholder="Search or add a tag"
          label="name"
          track-by="code"
          :closeOnSelect="false"
          :options="tagOptions"
          :multiple="true"
          :taggable="true"
          :internal-search="false"
          :loading="isLoadingTags"
          group-values="tags"
          group-label="name"
          tagPosition="bottom"
          openDirection="above"
          @search-change="loadTagOptions"
          @tag="addTag">
        </multiselect>
      </div>
    </div>
    <div v-if="hasMultipleFiles" slot="footer" class="mb-5 text-right">
      <b-button :disabled="fileIsUploading" class="mb-2 mr-2" variant="success" @click="spreadMultiFiles">{{ fileIsUploading ? "Uploading Files..." : "Spread Files" }}</b-button>
    </div>
  </vs-card>
</template>

<script>
  import vueDropzone from "vue2-dropzone";
  import Multiselect from 'vue-multiselect';
  import { OPTIONS, UTILS, VALIDATION } from "../../../utils/campaign_form.js"
  import { ASSET_TYPES } from "../../../utils/assets_lib.js"

  export default {
    props: [
      'tagOptions', 'loadTagOptions', 'isLoadingTags', 'addTagOption', 'publisher_id', 'bindingKey', 'removeAsset',
      'platform', 'spreadMultiFilesCB', 'preloadedData', 'fileIsUploading'
    ],
    name: 'NewAsset',
    data: function() {
      return {
        tags:         [],
        files:        {},
        isMedia:      false,
        caption:      '',
        headline:     '',
        assetType:    null,
        isBackgroud:  false,
        media_bucket: 'imgn-publishers'
      }
    },
    computed: {
      awss3: function() {
        return {
          signingURL: (f) => `${this.host}/assets/presigned_asset?file_name=${f.name}`,
          headers: { Authorization: localStorage.getItem("token") },
          params : {},
          sendFileToServer : false
        }
      },
      platformOptions: function() {
        return this.platform ? OPTIONS.platform(this.platform) : {};
      },
      anyfilesAdded: function() {
        return Object.keys(this.files).join(',')
      },
      videos: function() {
        return Object.fromEntries(Object.entries(this.files).filter(file => file[1].isVideo))
      },
      hasMultipleFiles: function() {
        return Object.keys(this.files).length > 1
      },
      showDropzone: function() {
        return this.platform && this.isMedia
      },
      assetTypes: function() {
        return ASSET_TYPES(this.platform)
      },
      subtype: function() {
        return this.assetType === 'Preview' ? 'preview_image' : null
      },
      realType: function() {
        return this.assetType === 'Preview' ? 'Media' : this.assetType
      }
    },
    watch: {
      assetType: function(val) {
        this.isMedia = val === 'Media' || val === 'Preview';
      },
      platform: function() {
        this.assetType = null
      }
    },
    mounted() {
      if (this.preloadedData) this.spreadData()
    },
    methods: {
      addTag(newTag) {
        const tag = {
          name: newTag,
          code: newTag
        }
        this.addTagOption(tag)
        this.tags.push(tag)
      },
      dropOptions() {
        return {
          acceptedFiles: "image/*,video/*",
          maxFilesize: 30,
          timeout: 180000,
          addRemoveLinks: true,
          autoProcessQueue: true,
          resize: function(file) {
            return {
              srcX:0,
              srcY:0,
              srcWidth: file.width,
              srcHeight: file.height,
              trgX:0,
              trgY:0,
              trgWidth: this.options.thumbnailWidth,
              trgHeight: parseInt(this.options.thumbnailWidth * file.height / file.width)
            }
          },
          headers: {},
          accept: VALIDATION.file(this.platformOptions, this.subtype)
        }
      },
      async fileUploadCompleted(file, _response) {
        let _file = {
          name:       file.name,
          isVideo:    file.type.includes('video'),
          subtype:    this.subtype,
          hasAudio:   false,
          media_key:  file.s3Signature.key
        }

        _file.signed_media = await UTILS.getSignedMedia(this.$http, this.host, file.s3Signature.key, this.media_bucket)

        this.$set(this.files, file.upload.uuid, _file)
        this.$eventHub.$emit("fileIsUploading", false, file.upload.uuid)
      },
      fileUploadStarted: function(file) {
        if (file.manuallyAdded) return;

        this.$eventHub.$emit("fileIsUploading", true, file.upload.uuid)
      },
      fileUploadCanceled: function(file) {
        this.$eventHub.$emit("fileIsUploading", false, file.upload.uuid)
      },
      fileRemoved: function(file, _error, _xhr) {
        this.$delete(this.files, file.upload.uuid)
      },
      spreadMultiFiles() {
        this.spreadMultiFilesCB(this.toObj(), this.bindingKey)
      },
      toObj() {
        let obj = {
          tags:       this.tags.map(t => t.name),
          type:       this.realType,
          assetType:  this.assetType
        }

        if (this.isMedia) {
          let assets = [];
          Object.entries(this.files).forEach(entry => {
            let [ uuid, file ] = entry
            assets.push(UTILS.clone({
              ...obj,
              uuid:         uuid,
              name:         file.name,
              subtype:      file.subtype,
              is_video:     file.isVideo,
              has_audio:    file.hasAudio,
              media_key:    file.media_key,
              media_bucket: this.media_bucket,
              signed_media: file.signed_media
            }))
          })
          return assets
        }

        return [UTILS.clone({
          ...obj,
          headline: this.headline,
          caption: this.caption
        })]
      },
      spreadData() {
        this.tags       = this.preloadedData.tags.map(t => ({name: t, value: t}))
        this.assetType  = this.preloadedData.assetType || 'Media'
        const { uuid }  = this.preloadedData

        this.$set(this.files, uuid, {
          name:         this.preloadedData.name,
          isVideo:      this.preloadedData.is_video,
          hasAudio:     this.preloadedData.has_audio,
          media_key:    this.preloadedData.media_key,
          signed_media: this.preloadedData.signed_media
        })

        this.$nextTick(() => {
          this.$refs.myVueDropzone.manuallyAddFile({ name: this.files[uuid].name, size: 100 }, this.files[uuid].signed_media)
          this.$refs.myVueDropzone.getFilesWithStatus()[0]['upload'] = { uuid: uuid }
        })
      }
    },
    components: {
      vueDropzone,
      Multiselect
    }
  }
</script>
<style>
  a.dz-remove {
    top: 0 !important;
    bottom: unset !important;
    right: 0 !important;
    z-index: 1200 !important;
  }

  .tags-picker {
    z-index: 10000;
  }

</style>
<style scoped>

  input.hidden-required {
    position: absolute;
    opacity: 0;
    z-index: -1;
    transform: translateY(300%);
  }

  .audio-btn {
    font-size: 1.2rem;
  }

  .audio-btn:hover {
    cursor: pointer;
  }

  .wf-radio {
    list-style:   none;
    visibility:   hidden;
  }

  .wf-radio:first-child {
    position: absolute;
  }

  .wf-radio-label {
    cursor: pointer;
  }

  .wf-radio:checked + label {
    border:       2px solid blue;
    font-weight:  bold;
    border-radius: 5px;
  }

  .wf-middle {
    padding-top: calc(0.250rem + 1px);
    padding-bottom: calc(0.250rem + 1px);
    margin-bottom: 0;
  }

</style>

