<template>
  <div>
    <form autocomplete="off" @submit.prevent="submit">
      <vs-card>
        <div class="form-group row justify-content-center">
          <label class="text-right col-sm-2 col-form-label">Platform</label>
          <div class="col-sm-4 wf-middle">
            <template v-for="platform in platforms">
              <input :key="platform.name" :id="platform.name" type="radio" class="wf-radio" :value="platform.name"
                v-model="account.platform" required /><label :for="platform.name" class="wf-radio-label"
                :key="`${platform.name}-lbl`">{{ platform.name }}</label>
            </template>
          </div>
        </div>

        <div class="form-group row justify-content-center">
          <label for="account_name" class="text-right col-sm-2 col-form-label">Name</label>
          <div class="col-sm-4">
            <input id="account_name" name="account_name" class="form-control" v-model="account.name" required />
          </div>
        </div>
        <div class="form-group row justify-content-center">
          <label for="account_ext_id" class="text-right col-sm-2 col-form-label">External ID</label>
          <div class="col-sm-4">
            <input id="account_ext_id" name="account_ext_id" class="form-control" v-model="account.external_id"
              required />
          </div>
        </div>
        <div class="form-group row justify-content-center">
          <label for="code_name" class="text-right col-sm-2 col-form-label">Code Name</label>
          <div class="col-sm-4">
            <input id="code_name" name="code_name" class="form-control" placeholder="e.g. DQ9"
              v-model="account.code_name" required />
          </div>
        </div>

        <div class="form-group row justify-content-center">
          <label for="timezone" class="text-right col-sm-2 col-form-label">Timezone</label>
          <div class="col-sm-4">
            <select id="timezone" required v-model="account.timezone" class="form-control">
              <option :key="opt.value" :value="opt.value" v-for="opt in timezones">
                {{ opt.label }}
              </option>
            </select>
          </div>
        </div>
      </vs-card>
      <b-button type="submit" class="mb-2" variant="success" :disabled="creating">
        {{ submitButtonText }}
      </b-button>
      <div v-if="submitError" class="alert alert-danger">{{ submitError }}</div>
    </form>
  </div>
</template>

<script>
import { PLATFORMS } from "../../../src/utils/product_entities";
import { AVAILABLE_TIMEZONES } from "../../utils/values.js";

export default {
  data: function () {
    return {
      account: {
        custom: {},
      },
      timezones: AVAILABLE_TIMEZONES,
      submitError: null,
      creating: false,
      platforms: PLATFORMS,
      mode: "new",
      submitButtonText: "Create Ad Account",
    };
  },
  mounted() {
    const { params } = this.$route;
    this.account.publisher_id = params.id;
    if (params.ad_account_id) {
      this.mode = "edit";

      this.submitButtonText = "Save Ad Account";
      this.$http
        .get(`${this.host}/ad_accounts/${params.ad_account_id}`)
        .then((res) => {
          this.account = res.data;
          this.account.custom = this.account.custom ? this.account.custom : {};
        });
    }
  },
  beforeDestroy() { },
  computed: {},
  watch: {},
  methods: {
    submit() {
      this.submitError = null;
      this.creating = true;

      let method = this.mode == "edit" ? this.$http.put : this.$http.post;

      method(
        `${this.host}/ad_accounts/${this.mode == "edit" ? this.account.id : ""
        }`,
        { ad_account: this.account }
      )
        .then(() => {
          this.$router.push({
            name: "edit-publishers",
            params: { id: this.account.publisher_id },
          });
        })
        .catch((err) => {
          this.submitError = err;
        })
        .finally(() => {
          this.creating = false;
        });
    },
  },
  components: {},
};
</script>
<style scoped>
.wf-radio {
  list-style: none;
  visibility: hidden;
}

.wf-radio:first-child {
  position: absolute;
}

.wf-radio-label {
  cursor: pointer;
}

.wf-radio:checked+label {
  border: 2px solid blue;
  font-weight: bold;
  border-radius: 5px;
}

.wf-middle {
  padding-top: calc(0.25rem + 1px);
  padding-bottom: calc(0.25rem + 1px);
  margin-bottom: 0;
}
</style>
