<template>
  <div class="col col-md-3 selected-assets">
    <vs-card>
      <vs-card>
        <div v-if="selectedAssets.text == null" slot="header">
          <h3>No caption selected</h3>
        </div>
        <div v-else class="row pl-6 p-3">
          <h5>{{ (selectedAssets.text || {}).headline }}</h5>
          <span> {{ (selectedAssets.text || {}).caption }} </span>
        </div>

        <div v-if="selectedAssets.creative_image.length === 0">
          <figure class="figure">
            <img :src="placeholderImage" class="figure-img img-fluid rounded" alt="No creatives selected."/>
            <figcaption class="figure-caption">No creatives selected</figcaption>
          </figure>
        </div>
        <div
          v-else
          v-for="mediaAsset in selectedAssets.creative_image"
          :key="mediaAsset.key"
          class="row selected-media pl-6 p-3"
        >
          <video
            v-if="((mediaAsset || {}).signed_media || '').includes('.mp4')"
            :src="(mediaAsset || {}).signed_media"
            class="modal-video"
            muted
            autoplay
            playsinline
            controls
          ></video>
          <img v-else :src="mediaAsset.signed_media" />
          <vs-button class="-m-3" radius type="gradient" color="danger" icon="delete" @click="removeAsset(mediaAsset)"
          />
        </div>
        <div v-if="!selectedAssets.preview_image || !selectedAssets.preview_image.id">
          <figure class="figure">
            <img :src="placeholderImage" class="figure-img img-fluid rounded" alt="No preview selected."/>
            <figcaption class="figure-caption">No preview selected</figcaption>
          </figure>
        </div>
        <div
          v-else
          :key="selectedAssets.preview_image.key"
          class="row selected-media pl-6 p-3"
        >
          <div>Preview:</div>
          <div><img :src="selectedAssets.preview_image.signed_media" /></div>
        </div>
        <vs-button
          :disabled="disabledDone"
          color="success"
          icon="done"
          @click="commitAssets"
        >Done
        </vs-button>
      </vs-card>

      <div v-if="commitedAssets.length > 0">
        <vs-divider color="success" icon="check"></vs-divider>

        <vs-card
          v-for="(commitedAsset, index) in commitedAssets"
          :key="commitedAsset.key"
        >
          <vs-button
            class="float-right -m-3"
            radius
            type="gradient"
            color="danger"
            icon="delete"
            @click="uncommitAsset(index)"
          />

          <div class="row pl-6 p-3">
            <h6>{{ (commitedAsset.text || {}).headline }}</h6>
            <span> {{ (commitedAsset.text || {}).caption }} </span>
          </div>

          <div
            v-for="commitedMediaAsset in commitedAsset.media"
            :key="commitedMediaAsset.key"
            class="row selected-media pl-6 p-3"
          >
            <video
              v-if="((commitedMediaAsset || {}).signed_media || '').includes('.mp4')"
              :src="(commitedMediaAsset || {}).signed_media"
              class="modal-video"
              muted
              playsinline
              controls
            ></video>
            <img v-else :src="commitedMediaAsset.signed_media" />
          </div>
        </vs-card>
      </div>
    </vs-card>
  </div>
</template>

<script>
import placeholderImage from "../../../assets/images/placeholders/placeholder.png";
export default {
  props: ["selectedAssets", "commitedAssets", "adsetNum"],
  computed: {
    disabledDone: function() {
      return (
        (this.selectedAssets.text           || []).length +
        (this.selectedAssets.preview_image  || []).length +
        (this.selectedAssets.creative_image || []).length
      ) === 0
    }
  },
  data: function() {
    return {
      placeholderImage: placeholderImage
    };
  },
  mounted() {
    this.$eventHub.$on("retrieveSelectedAssets", cb => {
      cb(this.commitedAssets);
    });
  },
  beforeDestroy() {
    this.$eventHub.$off("retrieveSelectedAssets");
  },
  methods: {
    removeAsset(asset) {
      let index = this.selectedAssets[asset.subtype].findIndex(
        m => m.media_key === asset.media_key
      );
      this.selectedAssets[asset.subtype].splice(index, 1);
    },
    commitAssets() {
      this.$eventHub.$emit("commitAssets", this.selectedAssets, this.adsetNum);
    },
    uncommitAsset(i) {
      if (window.confirm("Are you sure you want to remove the creatives?")) {
        this.$eventHub.$emit("uncommitAsset", i);
      }
    }
  }
};
</script>
<style>
.col.selected-assets {
  padding-top: 1rem;
  padding-bottom: 1.2rem;
}

.selected-media img,
.selected-media video {
  max-height: 50vh;
  max-width: 80%;
}

@media (min-width: 992px) {
  .selected-assets div.con-vs-card {
    height: 100%;
  }
}
</style>
