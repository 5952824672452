<template>
  <vs-card>
    <div v-if="submitError" class="alert alert-danger">{{ submitError }}</div>
    <table class="text-left table table-bordered table-responsive-md">
      <thead>
      <tr>
        <th>ID</th>
        <th>Partner</th>
        <th>
          Name
          <button v-on:click  = "openDuplicateTab()"
                  style       = "float: right"
                  :disabled   = "isGettingData || whitelisted.length === 0"
          >Duplicate with {{whitelisted.length}} selected
          </button>
          <button v-on:click  = "clearSelection()"
                  style       = "float: right; margin-right: 5px"
                  :disabled   = "isGettingData || whitelisted.length === 0"
          >Clear selection
          </button>
        </th>
        <th class="text-center">Impressions</th>
        <th class="text-center">Visible imp.</th>
        <th class="text-center">Clicks</th>
        <th class="text-center">Spend</th>
        <th class="text-center">CTR</th>
        <th class="text-center">CPM</th>
        <th class="text-center">CPA Conv.</th>
      </tr>
      </thead>
      <tbody>
      <tr v-for="item in items" :key="`${uid(item)}Key`">
        <td>{{ item['data_partner_audience_id'] }}</td>
        <td>{{ item['partner_name'] }}</td>
        <td v-bind:class="{ checked: item.checked }">
          <input v-model  = "whitelisted"
                 :id      = "`${uid(item)}Id`"
                 type     = "checkbox"
                 @click   = "item.checked = $event.target.checked"
                 :value   = "[ uid(item), item['audience_name'] ]"
          />
          <label style="margin-left: 5px" :for="`${uid(item)}Id`">{{ item['audience_name'] }}</label>
        </td>
        <td class="text-center">{{ item['impressions'] }}</td>
        <td class="text-center">{{ item['visible_impressions'] }}</td>
        <td class="text-center">{{ item['clicks'] }}</td>
        <td class="text-center">{{ item['spent'] }}</td>
        <td class="text-center">{{ item['ctr'].toFixed(3) }}</td>
        <td class="text-center">{{ item['cpm'] }}</td>
        <td class="text-center">{{ item['cpa_conversion_rate'] }}</td>
      </tr>
      <tr>
        <td colspan="10">
          <b-pagination v-model     = "page"
                        size        = "sm"
                        pills
                        align       = "center"
                        :limit      = "13"
                        :per-page   = "params.page_size"
                        last-number
                        first-number
                        :disabled   = "isGettingData"
                        :total-rows = "totalItems"
          >
            <template v-slot:ellipsis-text>
              <span v-if="isGettingData"><b-spinner small></b-spinner></span>
            </template>>
          </b-pagination>
        </td>
      </tr>
      </tbody>
    </table>
    <div>
    </div>
  </vs-card>
</template>

<script>
  import moment   from 'moment'
  import { datePickerRanges } from "../utils/values.js";

  export default {
    props: ['entityType', 'entity', 'modalSelectedDate'],
    data() {
      return {
        page:           1,
        range:          [],
        items:          [],
        params:         { page_size: 20 },
        campaign:       {},
        totalItems:     0,
        whitelisted:    [],
        submitError:    null,
        isGettingData:  false,
        rangeOptions: {
          alwaysShowCalendars: true,
          autoApply: true,
          ranges: datePickerRanges(),
        },
      }
    },
    mounted(){
      this.range =
        this.modalSelectedDate ? [this.modalSelectedDate.start, this.modalSelectedDate.end] : [moment(), moment()];
    },
    methods: {
      getData() {
        if(this.isGettingData) return;

        this.params.page    = this.page - 1;
        this.isGettingData  = true;
        this.$http.post(`${this.host}/campaigns/${this.entity.id}/audiences`, this.params)
          .then(res => {
            this.items      = res.data.items;
            this.campaign   = res.data.campaign;
            this.totalItems = res.data.total;
          })
          .catch(res => this.submitError = res.response.data.error)
          .finally(() => this.isGettingData = false)
      },
      getDates(value) {
        this.params.start = value.start || moment(value[0], 'DD/MM/YYYY').startOf('day');
        this.params.end   = value.end   || moment(value[1], 'DD/MM/YYYY').endOf('day');
        this.getData();
      },
      openDuplicateTab() {
        let audiences = encodeURIComponent(this.whitelisted.join(','));
        window.open(encodeURI(`#/campaigns/${this.entity.id}/duplicate?audiences=${audiences}`), "_blank");
      },
      clearSelection() {
        this.items.forEach(item => item.checked = false);
        this.whitelisted = [];
      },
      uid(site) {
        return `${site['partner_name']}-${site['data_partner_audience_id']}`.toLowerCase()
      }
    },
    watch: {
      range: function(value) {
        this.getDates(value);
      },
      page: function() {
        this.getData();
      },
      modalSelectedDate: {
        deep: true,
        handler(value) {
          this.getDates(value);
        }
      },
    }
  }
</script>
<style scoped>
  * {
    font-size: 12px;
  }
  .checked {
    background-color: #ebffef;
  }
</style>
