<template>
  <div>
    <div class="col sticky text-right">
      <b-button @click="toggleColumnsPicker" size="lg" variant="icon" :class="showColumnsPicker ? 'open-picker' : ''" class="primary settings-btn fas fa-cog" />
    </div>
    <div class="row">
    <div class="col">
      <ColumnsPicker :columnsStorageKey="'selectedColumnsArticles'" :reloadTable="reloadTable" v-if="showColumnsPicker" :closeWidget="toggleColumnsPicker"></ColumnsPicker>
    </div>
    </div>

    <div class="row">
      <div class="col main-wrapper">
        <Articles :tableReloaded="tableReloaded"/>
      </div> 
    </div>
  </div>
</template>

<script>
	import Articles from '../components/Articles.vue';
	import ColumnsPicker from '../components/settings/ColumnsPicker.vue';

	export default {
		data: function() {
			return {
        showColumnsPicker: false,
        tableReloaded: true
			}
		},
    methods: {
      reloadTable(){ 
        this.tableReloaded = false;
        this.$nextTick(() => {this.tableReloaded = true})
      },
      toggleColumnsPicker() {
        this.showColumnsPicker = !this.showColumnsPicker;
      }
    },
		components: {
			Articles,
      ColumnsPicker
		}
	}
</script>
<style scoped>
  button.settings-btn::before {
    font-size: 1.5rem;
    text-align: right;
  }

  button.settings-btn:focus {
    box-shadow: none;
  }

  button.open-picker {
    text-shadow: 0 0 1rem #007bff;
  }

  @media(max-width: 420px) {
    .main-wrapper {
      padding: 0 !important;
    }
  }
</style>

