<template>
  <div class="container-fluid pl-0 pr-0">
    <div class="row">
      <div class="col">
        <vs-card>
          <div class="table-container">
            <table class="table table-responsive-md">
              <thead>
                <tr>
                  <th class="text-center">ID</th>
                  <th>Name</th>
                  <th>Domain</th>
                  <th>FB Pixel</th>
                  <th>GA Profile</th>
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="publisher in publishers" :key="publisher.id">
                  <td class="text-center">
                    <b-badge>{{ publisher.id }}</b-badge>
                  </td>
                  <td>
                    <span>{{ publisher.display_name }}</span>
                  </td>
                  <td>
                    <span>{{ publisher.domain }}</span>
                  </td>
                  <td>
                    <span>{{ (publisher.custom || {}).fb_pixel }}</span>
                  </td>
                  <td>
                    <span>{{ publisher.ga_publisher }}</span>
                  </td>
                  <td>
                    <a @click="editPublisher(publisher)">Edit</a>
                  </td>
                </tr>
              </tbody>
            </table>
            <clip-loader color="#20313b" v-if="loading" />
          </div>
        </vs-card>
      </div>
    </div>
  </div>
</template>

<script>
import ClipLoader from "vue-spinner/src/ClipLoader.vue";

export default {
  data: function () {
    return {
      publishers: [],
      loading: false,
    };
  },
  mounted() {
    this.load();
  },
  computed: {},
  watch: {},
  methods: {
    editPublisher: function (publisher) {
        this.$router.push(`/admin/publishers/${publisher.id}/edit`)
    },
    load: function () {
      this.loading = true;
      this.$http
        .get(`${this.host}/publishers.json?full=true`)
        .then((res) => {
          this.publishers = res.data;
        })
        .finally((_) => {
          this.loading = false;
        });
    },
  },
  components: {
    ClipLoader,
  },
};
</script>
<style scoped></style>
