<template>
  <div>
    <form autocomplete="off" @submit.prevent="submit">
      <vs-card>
        <div slot="header"></div>
        <div v-if="!isNewVersion" class="form-group row justify-content-center">
          <label class="text-right col-sm-2 col-form-label"></label>
          <div class="col-sm-4">
            <h5 class="badges-h5">
              ID <b-badge class="m-1 ">{{ versionId }}</b-badge>
              Revision <b-badge class="m-1 version-badge">{{ versionNumber }}</b-badge>
            </h5>
          </div>
        </div>

        <div class="form-group row justify-content-center">
          <label class="text-right col-sm-2 col-form-label">Publisher</label>
          <div class="col-sm-4 wf-middle">
            <template v-for="pub in publishers">
              <input
                :key="pub.id"
                :id       = "pub.id"
                type      = "radio"
                class     = "wf-radio"
                :value    = "pub.id"
                @change   = "checkArticle"
                v-model   = "publisher_id"
                :disabled = "!isNewVersion"
                required
              ><label :for="pub.id" class="wf-radio-label" :key="pub.name">{{ pub.name }}</label>
            </template>
          </div>
        </div>

        <div class="form-group row justify-content-center">
          <label class="text-right col-sm-2 col-form-label">Platform</label>
          <div class="col-sm-4 wf-middle">
            <template v-for="pl in platforms">
              <input
                :key="pl.name"
                :id       = "pl.name"
                type      = "radio"
                class     = "wf-radio"
                :value    = "pl.name"
                v-model   = "platform"
                :disabled = "!isNewVersion"
                required
              ><label :for="pl.name" class="wf-radio-label" :key="pl.name">{{ pl.name }}</label>
            </template>
          </div>
        </div>

        <div class="form-group row justify-content-center">
          <label for="article_id" class="text-right col-sm-2 col-form-label">Article ID</label>
          <div class="col-sm-4">
            <input
              id        = "article_id"
              name      = "article_id"
              class     = "form-control"
              @change   = "checkArticle"
              v-model   = "article_id"
              :disabled = "!isNewVersion"
              required
            />
            <template v-if="lastCancelToken">
              <b-form-text>Searching...</b-form-text>
            </template>
            <template v-else>
              <b-form-valid-feedback :state="articleFound">{{ unEscape(articleInfo) }}</b-form-valid-feedback>
              <b-form-invalid-feedback :state="articleFound">{{ articleInfo }}</b-form-invalid-feedback>
            </template>
          </div>
        </div>
        <div class="form-group row pt-4 justify-content-center">
          <label for="note" class="text-right col-sm-2 col-form-label">Note</label>
          <div class="col-sm-4">
            <textarea class="form-control" v-model="note"/>
          </div>
        </div>
      </vs-card>
      <template>
        <NewAsset
          v-for               = "asset in assets"
          :key                = "asset"
          :platform           = "platform"
          :bindingKey         = "asset"
          :tagOptions         = "tagOptions"
          :removeAsset        = "removeAsset"
          :addTagOption       = "addTagOption"
          :publisher_id       = "publisher_id"
          :isLoadingTags      = "isLoadingTags"
          :loadTagOptions     = "loadTagOptions"
          :preloadedData      = "preloadedData[asset]"
          :fileIsUploading    = "filesQueue.length > 0"
          :spreadMultiFilesCB = "spreadMultiFilesCB"
        />
      </template>
      <vs-card >
        <div class="form-group row justify-content-center">
          <vs-button
            type      = "border"
            icon      = "add"
            color     = "primary"
            class     = "mt-2"
            @click    = "addAsset"
            :disabled = "!platform"
            radius
          />
        </div>
      </vs-card>
      <b-button
        type      = "submit"
        class     = "mb-2"
        variant   = "success"
        :disabled = "this.filesQueue.length > 0 || creating"
      >
        {{ submitButtonText }}
      </b-button>
      <div v-if="submitError" class="alert alert-danger">{{ submitError }}</div>
    </form>
  </div>
</template>

<script>
  import { CancelToken } from 'axios';
  import NewAsset from "./NewAsset.vue";
  import { UTILS } from "../../../utils/campaign_form.js";
  import { PUBLISHERS, PLATFORMS } from "../../../utils/product_entities.js";

  export default {
    data: function() {
      return {
        versionId: null,
        versionNumber: null,
        publisher_id: null,
        article_id: null,
        platform: null,
        note: '',
        articleInfo: null,
        articleFound: false,
        lastCancelToken: null,
        publishers: PUBLISHERS,
        platforms: PLATFORMS,
        creating: false,
        submitError: null,
        assets: [],
        filesQueue: [],
        tagOptions: [],
        newTags: [],
        isLoadingTags: false,
        preloadedData: {}
      }
    },
    mounted() {
      const { params } = this.$route
      if (params.id) {
        this.versionNumber = params.number
        this.versionId = params.id
        this.publisher_id = params.publisher_id
        this.platform = params.platform
        this.article_id = params.article_id
        this.note = params.note
      }

      this.loadTagOptions()
      this.addAsset()

      this.$eventHub.$on("fileIsUploading", (uploading, bindingKey) => {
        uploading ? this.filesQueue.push(bindingKey) : this.filesQueue = this.filesQueue.filter(f => f != bindingKey)
      })
    },
    beforeDestroy() {
      this.$eventHub.$off('fileIsUploading');
    },
    computed: {
      isNewVersion: function() {
        return !this.versionId
      },
      submitButtonText: function() {
        if (this.filesQueue.length > 0) return 'Uploading Files...'
        if (this.creating) return 'Saving...'

        return this.isNewVersion ? 'Create' : 'Save'
      }
    },
    watch: {
      platform: function() {
        this.loadTagOptions()
      }
    },
    methods: {
      loadTagOptions(input) {
        this.isLoadingTags = true;
        this.$http.get(`${this.host}/assets/tag_suggestions`, {
          params: {
            input: input,
            platform: this.platform
          }
        })
          .then(res => {
            this.tagOptions = [
              {
                name: "Popular",
                tags: (res.data.tags || []).map(tag => { return { code: tag, name: tag} } )
              },
              {
                name: "New",
                tags: this.newTags
              }
            ]
          }).finally(() => { this.isLoadingTags = false })
      },
      checkArticle() {
        if (!this.article_id || !this.publisher_id) return;

        let _this = this;
        this.$http.get(`${this.host}/articles/${this.article_id}`, {
          params: { publisher_id: this.publisher_id },
          cancelToken: new CancelToken(function executor(c) {
            if (_this.lastCancelToken) _this.lastCancelToken('Publisher or Article Updated');
            _this.lastCancelToken = c;
          })
        }).then(res => {
          this.articleInfo = res.data[this.article_id];
          this.articleFound = true;
          this.lastCancelToken = null;
        }).catch(err => {
          if (err.response.status == 404) {
            this.articleInfo = 'Article Not Found';
            this.lastCancelToken = null;
          } else {
            let reason = err.response ? err.response.data : 'Unknown Error'
            this.articleInfo = reason;
          }
          this.articleFound = false;
        })
      },
      unEscape(str) {
        return UTILS.unEscape(str)
      },
      addTagOption(newTag) {
        this.newTags.push(newTag);
      },
      spreadMultiFilesCB(assets, bindingKey) {
        let assetsData = {}
        assets.forEach((asset ) => {
          assetsData[UTILS.bindingKey()] = asset
        })

        this.preloadedData = assetsData

        this.assets.push(...Object.keys(assetsData))
        this.removeAsset(bindingKey)
      },
      addAsset() {
        this.assets.push(UTILS.bindingKey())
      },
      removeAsset(bindingKey) {
        this.filesQueue = this.filesQueue.filter(file => file != bindingKey)
        this.assets = this.assets.filter(asset => asset != bindingKey)
      },
      submit() {
        this.submitError = null;

        if (!this.articleFound && this.isNewVersion) {
          alert('Invalid Article')
          return;
        }

        if (this.assets.length === 0) {
          alert('Add At Least One Creative')
          return;
        }

        const assets = this.$children.filter(c => c.$options.name === "NewAsset").map(asset => asset.toObj()).flat()

        this.isNewVersion ? this.createVersion(assets) : this.addAssets(assets)
      },
      createVersion(assets){
        this.creating = true;

        this.$http.post(`${this.host}/versions`, {
          article_id: this.article_id,
          article_name: this.articleInfo,
          publisher_id: this.publisher_id,
          platform: this.platform,
          note: this.note,
          assets: assets
        }).then(() => {
          this.$router.push({name: 'library'})
        }).catch(err => {
          this.submitError = err
        }).finally(() => {
          this.creating = false
        })
      },
      addAssets(assets){
        this.creating = true;

        this.$http.put(`${this.host}/versions/${this.versionId}/add_assets`, {
          note: this.note,
          assets: assets
        }).then(() => {
          this.$router.push({name: 'library'})
        }).catch(err => {
          this.submitError = err
        }).finally(() => {
          this.creating = false
        })
      }
    },
    components: {
      NewAsset
    }
  }
</script>
<style scoped>

  .version-badge {
    background-color: var(--orange);
  }

  .wf-radio {
    list-style:   none;
    visibility:   hidden;
  }

  .wf-radio:first-child {
    position: absolute;
  }

  .wf-radio-label {
    cursor: pointer;
  }

  .wf-radio:checked + label {
    border:       2px solid blue;
    font-weight:  bold;
    border-radius: 5px;
  }

  .wf-middle {
    padding-top: calc(0.250rem + 1px);
    padding-bottom: calc(0.250rem + 1px);
    margin-bottom: 0;
  }

</style>

