<template>
  <div class="container-fluid pl-0 pr-0">
    <div class="row" v-if="!miniView">
      <div class="col">
        <vs-button
          size="small"
          class="mb-2 btn-sm"
          color="primary"
          @click="$router.push({ name: 'new-version' })"
          >New Version</vs-button
        >
        <vs-card v-if="!selectedVersion">
          <div class="row align-items-end filters search-form">
            <div class="col-12 d-flex flex-row">
              <div class="d-flex align-items-center input-container">
                <label class="input-control">Publisher</label>
                <select class="form-control" v-model="publisher_id">
                  <option :value="null" selected>All</option>
                  <option
                    :key="opt.name"
                    :value="opt.id"
                    v-for="opt in publishers"
                  >
                    {{ opt.name }}
                  </option>
                </select>
              </div>
              <div class="d-flex align-items-center input-container">
                <input
                  class="form-control"
                  type="text"
                  id="utmMedium"
                  placeholder="Article ID"
                  autocomplete="off"
                  v-model="article_id"
                />
              </div>
            </div>
          </div>
        </vs-card>
      </div>
    </div>

    <div class="row">
      <div class="col">
        <vs-card>
          <div class="table-container">
            <table class="table table-responsive-md">
              <thead>
                <tr>
                  <th class="text-center">ID</th>
                  <th class="text-center">Revision</th>
                  <th>Article Name</th>
                  <th class="text-center">Article ID</th>
                  <th>Note</th>
                  <th></th>
                  <th class="text-center"># Captions</th>
                  <th class="text-center"># Creatives</th>
                  <th class="text-center"># Previews</th>
                  <th class="text-center">Release Date</th>
                  <th class="text-center">Publisher</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(version, i) in visibleVersions" :key="version.id">
                  <td class="text-center">
                    <b-badge>{{ version.id }}</b-badge>
                  </td>
                  <td class="text-center">
                    <h5 class="badges-h5">
                      <b-badge class="m-1 version-badge">{{
                        version.number
                      }}</b-badge>
                    </h5>
                  </td>
                  <td class="article-name-cell">
                    {{ unEscape(version.article_name) }}
                  </td>
                  <td class="text-center">
                    {{ version.article_id }}
                  </td>
                  <td class="note-cell">
                    <template v-if="savingNote && noteEdited === version.id">
                      Saving...
                    </template>
                    <span v-else class="input-group note-container">
                      <span v-if="noteEdited !== version.id">{{
                        version.note
                      }}</span>
                      <input
                        v-else
                        type="text"
                        class="form-control"
                        v-model="noteInput"
                      />
                      <span>
                        <i
                          class="fas fa-pencil text-secondary pointer"
                          v-if="!noteEdited && !savingNote"
                          @click="editNote(version)"
                        ></i>
                        <template v-else-if="noteEdited === version.id">
                          <i
                            class="fas fa-check text-secondary pointer"
                            @click="saveNote(version, i)"
                          ></i>
                          <i
                            class="fas fa-history text-secondary pointer"
                            @click="revertNote()"
                          ></i>
                        </template>
                      </span>
                    </span>
                  </td>
                  <td class="icons-cell">
                    <h5 class="badges-h5">
                      <i
                        @click.stop="toggleLock(version, i)"
                        :class="version.locked ? 'fa-lock' : 'fa-lock-open'"
                        class="fas pointer"
                      ></i>
                      <i
                        v-if="version.platform === 'push'"
                        class="fal fa-bells"
                      ></i>
                      <img
                        src="./../../../components/images/taboola.png"
                        class="taboola-icon"
                        v-else-if="version.platform === 'taboola'"
                      />
                      <i
                        v-else-if="version.platform === 'tiktok'"
                        class="fas fa-music-alt"
                      ></i>
                      <i
                        v-else
                        :class="`fab fa-${version.platform}${
                          version.platform === 'facebook'
                            ? '-f'
                            : version.platform === 'snapchat'
                            ? '-ghost'
                            : ''
                        }`"
                      ></i>
                    </h5>
                  </td>
                  <td class="text-center">{{ version.captions_count }}</td>
                  <td class="text-center">{{ version.creatives_count }}</td>
                  <td class="text-center">{{ version.previews_count }}</td>
                  <td class="text-center">
                    {{ formatCreationDate(version.created_at) }}
                  </td>
                  <td
                    class="text-center site-code"
                    :style="`color: ${
                      publisherObject(version.publisher_id).color
                    }`"
                  >
                    {{ publisherObject(version.publisher_id).code }}
                  </td>
                  <td>
                    <h5 class="badges-h5 icons">
                      <div class="flex">
                        <button
                          style="background-color: #0c8130"
                          @click="addCreatives(version)"
                          v-if="!miniView"
                        >
                          <img src="@/assets/images/icons/plus-icon.svg" />
                        </button>
                        <button
                          :style="`background-color: ${
                            selectedVersion ? '#9F9F9F' : '#F9C10A'
                          }`"
                          @click="toggleSelect(version.id)"
                          v-bind:class="{ ['badge-light']: !selectedVersion }"
                        >
                          <img
                            v-if="selectedVersion"
                            src="@/assets/images/icons/arrow-icon.svg"
                          />
                          <img
                            v-else
                            src="@/assets/images/icons/eye-icon.svg"
                          />
                        </button>
                        <button
                          style="background-color: #b71727"
                          @click="deleteLibrary(version)"
                        >
                          <img src="@/assets/images/icons/trash-icon.svg" />
                        </button>
                      </div>
                    </h5>
                  </td>
                </tr>
              </tbody>
            </table>
            <clip-loader color="#20313b" v-if="loading" />
          </div>
          <div v-if="!selectedVersion" slot="extra-content" class="pb-3">
            <b-pagination
              pills
              v-model="currentPage"
              align="center"
              :total-rows="totalVersions"
              :per-page="versionsPerPage"
            ></b-pagination>
            <div class="text-center">
              Versions per page: {{ versionsPerPage }} - Total Versions:
              {{ totalVersions }}
            </div>
          </div>
        </vs-card>

        <div v-if="selectedVersion">
          <template v-for="assetType in assetTypes">
            <AssetsCard
              :key="assetType"
              :version="visibleVersions[0]"
              :miniView="miniView"
              :assetType="assetType"
              :selectedAssets="selectedAssets || selectedItems"
              :commitedAssets="commitedAssets"
            />
          </template>

          <div v-if="!miniView">
            <FormSubmitter
              path="versions/multi_duplicate"
              :disabled="!itemsSelected || !selectedPublishers.length"
              @onSuccess="duplicated"
              submitLabel="Duplicate"
              :showPending="true"
              :feedbackObject="feedbackObject"
            >
              <span class="pr-1">Duplicate for:</span>
              <section class="flex gap-3 mb-3">
                <div
                  class="flex flex-col"
                  v-for="publisher in publishers"
                  :key="publisher.id"
                >
                  <div>
                    <input
                      :id="publisher.id"
                      type="checkbox"
                      :value="publisher.id"
                      name="publisher_ids[]"
                      v-bind:checked="selectedPublishers.includes(publisher.id)"
                      @change="togglePublisherSelection(publisher.id)"
                    />
                    <label :for="publisher.id" class="px-1 mr-1">{{
                      publisher.name
                    }}</label>
                  </div>

                  <div
                    class="d-flex align-items-center input-container"
                    v-if="selectedPublishers.includes(publisher.id)"
                  >
                    <input
                      class="form-control"
                      type="text"
                      :id="'articleId' + publisher.id"
                      name="article_ids[]"
                      placeholder="Article ID"
                      autocomplete="off"
                    />
                  </div>
                </div>
              </section>
              <input
                type="hidden"
                name="text"
                :value="selectedItems.text.map((t) => t.id)"
              />
              <input
                type="hidden"
                name="media"
                :value="selectedItems.creative_image.map((m) => m.id)"
              />
              <input
                type="hidden"
                name="preview"
                :value="selectedItems.preview_image.map((m) => m.id)"
              />
            </FormSubmitter>
          </div>
          <div>
            <span :class="feedbackObject.class">{{ feedbackObject.text }}</span>
          </div>
        </div>
      </div>

      <SelectionNav
        v-if="miniView"
        :selectedAssets="selectedAssets"
        :commitedAssets="commitedAssets"
        :adsetNum="adsetNum"
      />
    </div>
  </div>
</template>

<script>
import AssetsCard from "./AssetsCard.vue";
import SelectionNav from "./SelectionNav.vue";
import { CancelToken } from "axios";
import moment from "moment";
import { UTILS } from "../../../utils/campaign_form.js";
import { PUBLISHERS } from "../../../utils/product_entities.js";
import { ASSET_TYPES } from "../../../utils/assets_lib.js";
import ClipLoader from "vue-spinner/src/ClipLoader.vue";

export default {
  props: [
    "miniView",
    "formFilters",
    "selectedAssets",
    "commitedAssets",
    "adsetNum",
  ],
  data: function () {
    return {
      publishers: PUBLISHERS,
      publisher_id: null,
      article_id: null,
      article_ids: [],
      platform: null,
      noteInput: "",
      noteEdited: null,
      savingNote: false,
      versions: [],
      selectedVersion: null,
      totalVersions: 0,
      versionsPerPage: 20,
      selectedPublishers: [],
      articleIdsByPublisher: {},
      selectedItems: { creative_image: [], text: [], preview_image: [] },
      feedbackObject: { class: "", text: "", timestamp: null },
      articleId: null,
      currentPage: 1,
      loading: false,
      lastCancelToken: null,
    };
  },
  mounted() {
    if (this.formFilters) {
      this.publisher_id = this.formFilters.publisher_id;
      this.article_id = this.formFilters.article_id;
      this.platform = this.formFilters.platform;
    }

    this.loadVersions();
  },
  computed: {
    visibleVersions: function () {
      if (this.selectedVersion) {
        return this.versions.filter(
          (version) => version.id === this.selectedVersion
        );
      }

      return this.versions;
    },
    itemsSelected: function () {
      return (
        this.selectedItems.creative_image.length > 0 ||
        this.selectedItems.preview_image.length > 0 ||
        this.selectedItems.text.length > 0
      );
    },
    filters: function () {
      return {
        publisher_id: this.publisher_id,
        article_id: this.article_id,
        platform: this.platform,
      };
    },
    assetTypes: function () {
      return ASSET_TYPES(this.visibleVersions[0].platform);
    },
  },
  watch: {
    currentPage: function () {
      this.loadVersions();
    },
    filters: {
      deep: true,
      handler: function () {
        this.currentPage = 1;
        this.loadVersions();
      },
    },
  },
  methods: {
    loadVersions: function () {
      let _this = this;
      this.loading = true;

      this.$http
        .get(`${this.host}/versions`, {
          params: {
            offset: (this.currentPage - 1) * this.versionsPerPage,
            limit: this.versionsPerPage,
            publisher_id: this.publisher_id,
            article_id: this.article_id,
            platform: this.platform,
          },
          cancelToken: new CancelToken(function executor(c) {
            if (_this.lastCancelToken) _this.lastCancelToken("Filters Updated");
            _this.lastCancelToken = c;
          }),
        })
        .then((res) => {
          this.versions = res.data.versions;
          this.totalVersions = res.data.total_versions;
        })
        .catch(() => {})
        .finally(() => (this.loading = false));
    },
    toggleSelect(id) {
      this.selectedVersion = this.selectedVersion === id ? null : id;
    },
    deleteLibrary(version) {
      if (
        confirm(`Are you sure you want to delete the library ${version.id}?`)
      ) {
        this.$http.delete(`${this.host}/versions/${version.id}`).then((res) => {
          if (res.status == 200) {
            this.versions = this.versions.filter((v) => {
              return v.id != version.id;
            });
          }
          alert(res.data.message);
        });
      }
    },
    toggleLock(version, i) {
      let newVal = !version.locked;
      if (
        !confirm(`Are you sure to ${newVal ? "Lock" : "Unlock"} this version?`)
      )
        return;

      this.$http
        .put(`${this.host}/versions/${version.id}/lock`, {
          locked: newVal,
        })
        .then(() => {
          version.locked = newVal;
          this.$set(this.versions, i, version);
        });
    },
    togglePublisherSelection(publisherId) {
      if (this.selectedPublishers.includes(publisherId)) {
        this.selectedPublishers = this.selectedPublishers.filter(
          (id) => id !== publisherId
        );
      } else {
        this.selectedPublishers.push(publisherId);
        this.$set(this.articleIdsByPublisher, publisherId, []); // initialize empty array for publisher's article IDs
      }
    },
    updateArticleId(publisherId, index, value) {
      this.articleIdsByPublisher[publisherId][index] = value;
    },
    duplicated() {
      this.selectedPublishers = [];
      this.feedbackObject.text = "Duplicated";
      setTimeout(() => {
        this.loadVersions();
      }, 500);
    },
    formatCreationDate(datetime) {
      return moment(datetime).format("DD/MM/YYYY");
    },
    publisherObject(pubId) {
      return PUBLISHERS.find((p) => p.id === pubId) || {};
    },
    editNote(version) {
      this.noteInput = version.note;
      this.noteEdited = version.id;
    },
    saveNote(version, i) {
      let newVal = this.noteInput;
      this.savingNote = true;
      this.$http
        .put(`${this.host}/versions/${version.id}/note`, {
          note: newVal,
        })
        .then(() => {
          version.note = newVal;
          this.$set(this.versions, i, version);
          this.noteInput = null;
          this.noteEdited = null;
        })
        .finally(() => (this.savingNote = false));
    },
    revertNote() {
      this.noteInput = null;
      this.noteEdited = null;
    },
    addCreatives(version) {
      this.$router.push({
        name: "add-creatives",
        meta: { pageTitle: `Version ${version.id}` },
        params: version,
      });
    },
    unEscape(str) {
      return UTILS.unEscape(str);
    },
  },
  components: {
    AssetsCard,
    SelectionNav,
    ClipLoader,
  },
};
</script>
<style scoped>
.site-code {
  font-size: 18px;
  font-weight: bold;
}

.table-container {
  overflow-x: scroll;
}

.icons-container {
  width: 100%;
}

.fa-lock {
  color: brown;
}

.fa-lock-open {
  color: grey;
}

.pointer:hover {
  cursor: pointer;
}

i {
  font-size: 1.2rem;
  margin: 0.3rem;
}

h5.badges-h5 {
  margin-bottom: auto;
}

.card-title {
  margin: 0;
}

.icons-cell {
  width: 10%;
}

.article-name-cell {
  width: 20%;
}

.note-cell {
  width: 40%;
  word-wrap: break-word;
}

.version-badge {
  background-color: var(--orange);
}

.media-icon {
  z-index: 10;
  padding: 0.5rem;
  color: white;
  text-shadow: 0.1rem 0.1rem black, 0.1rem 0.1rem 01rem black;
}

.media-icons-container {
  display: flex;
  position: absolute;
  width: 100%;
  justify-content: space-between;
}

i.media-dimentions {
  font-size: 1rem;
  font-style: unset;
}

.taboola-icon {
  height: 17px;
}

.selected {
  border: 1px solid var(--primary);
  background-color: #b8daff;
}

.error {
  color: red;
}
.success {
  color: green;
}

.note-container {
  justify-content: space-between;
}

.container {
  height: fit-content;
}

@media (min-width: 992px) {
  .col.scroll-lib {
    padding-top: 1rem;
    height: 80vh;
    overflow-y: auto;
  }
}
</style>

<style lang="scss">
.badges-h5.icons > div {
  width: 110px;
  justify-content: space-between;

  button {
    height: 33px;
    width: 33px;
    border: 0px;
    border-radius: 5px;

    img {
      height: 21px;
    }
  }
}
</style>
