<template>
  <div>
    <div class="flex mb-4">
      <label for="period" class="mt-2">Period</label>
      <date-range-picker
        class="form-control ml-2 w-32"
        v-model="range"
        :options="rangeOptions"
      />
    </div>
    <div v-if="isLoading" class="my-4">
      <b-spinner label="Loading..."></b-spinner>
    </div>
    <div v-else-if="metrics == null || Object.keys(metrics).length === 0" class="my-4">
      <h3>No campaigns reached their budget 💸</h3>
    </div>
    <div v-else class="table-responsive">
      <div class="disclaimer">
        * budget reached = spend &ge; 95% of daily budget
      </div>
      <div class="disclaimer">
        * results only apply to EST and Taboola campaigns
      </div>
      <table class="table table-striped">
        <thead>
          <tr class="header">
            <template v-for="header in Object.values(metricsColumns)">
              <th :key="header['label']">
                {{ header["label"].toUpperCase() }}
              </th>
            </template>
          </tr>
        </thead>
        <tbody>
          <template v-for="(campaignMetrics, campaignName) in metrics">
            <tr :key="campaignName">
              <template v-for="(value, key) in metricsColumns">
                <td :key="key">
                  {{
                    key == "name"
                      ? campaignName
                      : numeral(campaignMetrics[key] || 0).format(
                          value["format"]
                        )
                  }}
                </td>
              </template>
            </tr>
          </template>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import numeral from "numeral";
import moment from "moment-timezone";
import { datePickerRanges } from "../utils/values.js";

export default {
  name: "PublishersOverview",
  data() {
    return {
      metrics: [],
      metricsColumns: {
        name: {
          label: "Campaign",
          format: null
        },
        revenue: {
          label: "Revenue",
          format: "$0,0.000"
        },
        profit: {
          label: "Profit",
          format: "$0,0.000"
        },
        margin: {
          label: "Margin",
          format: "%0,0.00"
        },
        spend: {
          label: "Spend",
          format: "$0,0.000"
        },
        daily_budget: {
          label: "Daily Budget",
          format: "$0,0"
        },
        clicks: {
          label: "Clicks",
          format: "0,0"
        },
        cpc: {
          label: "CPC",
          format: "$0,0.000"
        },
        user_value: {
          label: "User value",
          format: "$0,0.0000"
        },
        rpm: {
          label: "RPM",
          format: "$0,0.000"
        }
      },
      isLoading: true,
      numeral: numeral,
      moment: moment,
      datePickerRanges: datePickerRanges,
      range: [moment().startOf("day"), moment().endOf("day")],
      rangeOptions: {
        singleDatePicker: true,
        alwaysShowCalendars: true,
        autoApply: true,
        maxDate: moment().add(1, "days")
      },
      selectedDate: {
        start: moment().tz('EST').startOf("day"),
        end: moment().tz('EST').endOf("day")
      }
    };
  },
  mounted() {
    this.loadPublisherMetrics();
  },
  watch: {
    range: function(n, _) {
      let start = moment(n, "DD/MM/YYYY").tz('EST').add(1, "days").startOf("day");
      let end = moment(n, "DD/MM/YYYY").tz('EST').add(1, "days").endOf("day");
      this.$set(this, "selectedDate", { start, end });

      this.loadPublisherMetrics();
    }
  },
  methods: {
    loadPublisherMetrics() {
      this.isLoading = true;
      this.$http
        .get(`${this.host}/campaigns/budget_reached`, {
          params: {
            start: this.selectedDate.start,
            end: this.selectedDate.end,
            timezone: "EST"
          }
        })
        .then(res => {
          this.metrics = res.data.metrics;
          this.isLoading = false;
        });
    }
  }
};
</script>

<style scoped>
.disclaimer {
  margin: 0.5rem;
  font-style: italic;
  text-align: end;
}

.header {
  background-color: #0f163a;
  font-weight: bold;
  color: white;
}
</style>
