<template>
  <vs-card>
    <div class="text-center" v-if="loading">
      <b-spinner label="Loading.."></b-spinner>
    </div>
    <template v-else-if="this.assets.length === 0">
      <div class="text-center">No {{ typeCaption }} found</div>
    </template>
    <template v-else>
      <div v-if="isMedia" class="row">
        <div class="col-md-12 col-lg-2" v-for="(asset, i) in assets" :key="asset.id">
          <b-card
            @click="toggleSelect(asset)"
            v-bind:class="{ selected: selectedMediaAsset(asset), flagged: asset.flagged }"
            no-body
          >
            <b-card-title class="icons-container d-flex m-2">
              <h5 class="badges-h5">
                <b-badge class="m-1">ID: {{ asset.id }}</b-badge>
                <b-badge class="m-1 date-badge">{{formatCreationDate(asset.created_at)}}</b-badge>
                <i
                  @click.stop="toggleFlag(asset, i)"
                  :class="asset.flagged ? 'fas flagged' : 'far'"
                  class="fa-flag pointer"
                ></i>
              </h5>
            </b-card-title>
            <b-card-title>
              <span class="media-icons-container">
                <i class="media-icon media-dimentions">{{ asset.mediaDimentions }}</i>
                <i
                  v-if="(asset.signed_media || '').includes('.mp4')"
                  :class="asset.has_audio ? 'fas fa-volume' : 'fas fa-volume-mute'"
                  class="media-icon"
                ></i>
                <span v-if="assetType === 'Preview'" class="media-icon preview">PREVIEW</span>
                <i @click="toggleModal(asset)" class="media-icon fas fa-search pointer"></i>
              </span>

              <video
                @loadeddata="saveMediaDimensions(asset, i)"
                :id="`video${asset.id}`"
                class="card-img"
                v-if="(asset.signed_media || '').includes('.mp4')"
                :src="asset.signed_media"
                :controls="false"
              ></video>
              <img
                @load="saveMediaDimensions(asset, i)"
                :id="`img${asset.id}`"
                v-else
                class="card-img"
                :src="asset.signed_media"
              />
            </b-card-title>

            <b-card-footer>
              <h5 class="badges-h5">
                <b-badge
                  class="m-1 tag-badge"
                  v-for="tag in asset.tags"
                  :key="tag"
                  >{{ tag }}</b-badge
                >
              </h5>
            </b-card-footer>

            <vs-avatar
              v-if="inModal && commitedAssets.some((a) =>a.creative_image.some((m) => m.id === asset.id))"
              class="my-0 mx-auto"
              icon="done"
            />
          </b-card>
        </div>
        <b-modal
          id="fullMediaViewModal"
          :title="`${modalAsset.id}`"
          centered
          hide-footer
        >
          <div class="d-block text-center">
            <video
              class="modal-video"
              v-if="(modalAsset.signed_media || '').includes('.mp4')"
              :src="modalAsset.signed_media"
              muted
              playsinline
              controls
            ></video>
            <img v-else :src="modalAsset.signed_media" />
          </div>
        </b-modal>
      </div>
      <div v-else class="table-container">
        <table class="table table-responsive-md">
          <thead>
            <tr>
              <th>ID</th>
              <th></th>
              <th class="text-center"></th>
              <th class="text-center">Date</th>
              <th>Tags</th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="(asset, i) in assets"
              :key="asset.id"
              @click="toggleSelect(asset)"
              v-bind:class="textClass(asset)"
            >
              <td>{{ asset.id }}</td>
              <td class="asset-text-cell">
                <div>
                  <h5>{{ asset.headline }}</h5>
                  <span> {{ asset.caption }} </span>
                </div>
              </td>
              <td class="asset-icons-cell text-center">
                <h5 class="badges-h5">
                  <i
                    @click.stop="toggleFlag(asset, i)"
                    :class="asset.flagged ? 'fas flagged' : 'far'"
                    class="fa-flag pointer"
                  ></i>
                </h5>
              </td>
              <td class="text-center">
                {{ formatCreationDate(asset.created_at) }}
              </td>
              <td class="asset-tags-cell">
                <h5 class="badges-h5">
                  <b-badge
                    class="m-1 tag-badge"
                    v-for="tag in asset.tags"
                    :key="tag"
                    >{{ tag }}</b-badge
                  >
                </h5>
              </td>
              <td v-if="inModal && commitedAssets.some((a) => a.text.id === asset.id)">
                <vs-avatar class="my-0 mx-auto" icon="done" />
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </template>
  </vs-card>
</template>

<script>
import moment from "moment";
import { CancelToken } from "axios";

export default {
  props: [ 'version', 'miniView', 'assetType', 'selectedAssets', 'commitedAssets' ],
  name: 'AssetsCard',
  data: function () {
    return {
      assets:           [],
      inModal:          this.miniView,
      loading:          false,
      modalAsset:       {},
      totalAssets:      0,
      lastCancelToken:  null
    };
  },
  mounted() {
    this.getAssets();
  },
  computed: {
    typeCaption: function() {
      if (this.assetType === 'Text')  return 'captions';

      if (this.assetType === 'Media') return 'creatives';

      return 'previews';
    },
    baseType: function() {
      return this.assetType === 'Preview' ? 'Media' : this.assetType
    },
    subtype: function() {
      return this.assetType === 'Preview' ? 'preview_image' : null
    },
    isMedia: function() {
      return this.baseType === 'Media'
    }
  },
  methods: {
    toggleModal(asset) {
      this.modalAsset = asset;
      this.$bvModal.show("fullMediaViewModal");
    },
    toggleSelect(asset) {
      const type = this.isMedia ? asset.subtype : 'text'
      const _this = this
      if (this.inModal && type !== 'creative_image') {
        if (this.selectedAssets[type] && this.selectedAssets[type].id === asset.id) this.selectedAssets[type] = null
        else this.selectedAssets[type] = asset
      } else if (this.baseType === 'Media') {
        let index = this.selectedAssets[type].findIndex(m => m.media_key === asset.media_key);
        if (index >= 0) this.selectedAssets[type].splice(index, 1)
        else this.selectedAssets[type].push(asset);
      } else {
        let index = this.selectedAssets[type].findIndex(t => t.id === asset.id);
        if (index >= 0) this.selectedAssets[type].splice(index, 1)
        else this.selectedAssets[type].push(asset);
      }
    },
    textClass(asset) {
      return {
        flagged:            asset.flagged,
        selected:           (this.selectedAssets.text || '').map ? this.selectedAssets.text.map(t=>t.id).includes(asset.id): false,
        ['table-primary']:  this.inModal && this.selectedAssets.text && this.selectedAssets.text.id === asset.id
      }
    },
    selectedMediaAsset(asset) {
      const selected = this.selectedAssets[asset.subtype];
      if (!selected) return false;

      if (Array.isArray(selected)) return selected.includes(asset);

      return selected.id === asset.id;
    },
    toggleFlag(asset, i) {
      let newVal = !asset.flagged;
      this.$http
        .put(`${this.host}/assets/${asset.id}/flag`, {
          flag: newVal,
        })
        .then(() => {
          asset.flagged = newVal;
          this.$set(this.assets, i, asset);
        });
    },
    saveMediaDimensions(asset, i) {
      let isVideo = (asset.signed_media || "").includes(".mp4");
      let media = document.getElementById(
        `${isVideo ? "video" : "img"}${asset.id}`
      );
      if (!media) return;
      let prefix = isVideo ? "video" : "natural";
      asset.mediaDimentions = `${media[`${prefix}Width`]} X ${
        media[`${prefix}Height`]
      }`;
      this.$set(this.assets, i, asset);
    },
    getAssets: function () {
      let _this = this;
      this.assets = [];
      this.loading = true;

      this.$http
        .get(`${this.host}/versions/${this.version.id}/assets`, {
          params: { type: this.baseType, subtype: this.subtype },
          cancelToken: new CancelToken(function executor(c) {
            if (_this.lastCancelToken) _this.lastCancelToken("Filters Updated");
            _this.lastCancelToken = c;
          }),
        })
        .then((res) => {
          this.assets = res.data.assets;
          this.totalAssets = res.data.total_assets;
        })
        .catch(() => {})
        .finally(() => (this.loading = false));
    },
    formatCreationDate(datetime) {
      return moment(datetime).format("DD.MM.YY");
    },
  },
};
</script>

<style scoped>
.table-container {
  overflow-x: scroll;
}

.icons-container {
  width: 100%;
}

.fa-flag {
  color: #dadada;
}

.fa-flag.flagged {
  color: red;
}

.pointer:hover {
  cursor: pointer;
}

i {
  font-size: 1.2rem;
  margin: 0.3rem;
}

h5.badges-h5 {
  margin-bottom: auto;
}

.card-title {
  margin: 0;
}

.asset-text-cell {
  width: 55%;
  word-wrap: break-word;
}

.asset-icons-cell {
  width: 15%;
}

.asset-tags-cell {
  width: 25%;
}

.selected {
  border: 1px solid var(--primary);
  background-color: #b8daff;
}

.tag-badge {
  background-color: var(--teal);
}

.date-badge {
  background-color: var(--info);
}

.media-icon {
  z-index: 10;
  padding: 0.5rem;
  color: white;
  text-shadow: 0.15rem 0.15rem black, 0.15rem 0.15rem 0.15rem black;
}

.preview {
  color: red;
}

.flagged {
  background-color: lightpink;
}

.media-icons-container {
  display: flex;
  position: absolute;
  width: 100%;
  justify-content: space-between;
}

i.media-dimentions {
  font-size: 1rem;
  font-style: unset;
}
</style>
<style>
#fullMediaViewModal___BV_modal_outer_ {
  z-index: 9999999 !important;
}
#fullMediaViewModal div.modal-dialog {
  width: fit-content;
}

#fullMediaViewModal img,
#fullMediaViewModal video {
  max-width: 90vw;
  max-height: 80vh;
}
</style>
